import React, {useEffect, useState} from "react";
import DaumPostcode from "react-daum-postcode";
import {useNavigate} from "react-router-dom";
import { Typography, Grid, Button, Box, Backdrop, CircularProgress} from "@mui/material";
import Modal from "@mui/material/Modal";
import BusinessInfo from "./components/BusinessInfo";
import FileInfo from "./components/FileInfo";
import axios from "../axiosInstance";
import {useRecoilValue} from "recoil";
import {memberState} from "../store/MemberStore";

const fileNameList1 = [
    '신분증(사업자등록증)',
    "간판사진",
    "매장사진1",
    "매장사진2",
    "통장사본",
    "단말기 시리얼"
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '85%',
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
};

const SignUpForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        shopOwner: "",
        shopTitle: "",
        shopTell: "",
        shopType: "",
        shopItem: "",
        shopAddr1: "",
        shopAddr2: "",
        shopAddrDetail: "",
        shopCharge: "",
        shopBankNum: "",
        shopBankName: "",
        shopBankOwner: "",
        memo: "",
        postName: "",
        postTell: "",
        postAddr1: "",
        postAddr2: "",
        postAddrDetail: "",
        lv4: "",
        lv5: "",
        lv6: "",
        method: '1'
    });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(["010", "", ""]);
    const [phoneNumber2, setPhoneNumber2] = useState(["010", "", ""]);
    const fileNameList = fileNameList1;
    const [addrTarget, setAddressTarget] = useState();
    const memberInfo = useRecoilValue(memberState);
    const [bankInfo, setBankInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getBankInfo();
    }, []);

    const getBankInfo = () => {
        axios.get('/api/shop/bankList').then(response => {
            setBankInfo(response.data);
        })
    }
    const handleFileChange = (index) => (event) => {
        const file = event.target.files[0];
        if (file) {
            const originalExtension = file.name.split(".").pop(); // 원본 파일의 확장자 추출
            const newFileName = `${event.target.name}.${originalExtension}`; // 새 파일명 생성

            const newFile = new File([file], newFileName, {type: file.type}); // 새 파일 객체 생성

            setSelectedFiles((prevFiles) => {
                let newFiles = [...prevFiles];
                newFiles[index] = newFile; // 새로운 파일명으로 해당 인덱스에 저장
                // 배열의 빈 항목들 제거
                newFiles = newFiles.filter(
                    (file) => file != null && file !== ""
                );
                return newFiles;
            });
        }
    };
    const handleChange = (e) => {
        const field = e.target.name;
        setFormData({...formData, [field]: e.target.value});
    };

    const phoneNumberHandler = (e, idx) => {
        const newPhoneNumber = [...phoneNumber];
        newPhoneNumber[idx] = e.target.value;
        setPhoneNumber(newPhoneNumber);
        setFormData({
            ...formData,
            shopTell: `${newPhoneNumber[0]}-${newPhoneNumber[1]}-${newPhoneNumber[2]}`,
        });
    };


    const phoneNumberHandler2 = (e, idx) => {
        const newPhoneNumber = [...phoneNumber2];
        newPhoneNumber[idx] = e.target.value;
        setPhoneNumber2(newPhoneNumber);
        setFormData({
            ...formData,
            postTell: `${newPhoneNumber[0]}-${newPhoneNumber[1]}-${newPhoneNumber[2]}`,
        });
    };

    const handleOpen = (event) => {
        setOpen(true);
        setAddressTarget(event.target.name);
    };
    const handleClose = () => setOpen(false);
    // 정규식 검사 함수들
    const isValidKoreanName = (name) => /^[가-힣]{2,4}$/.test(name);
    // 전화번호 각 부분 유효성 검사: 3자리 또는 4자리 숫자
    const isValidPhoneNumber = (number) => /^\d{3,4}$/.test(number);
    // 한글 텍스트 유효성 검사: 최대 10글자 한글
    const isValidKoreanText = (text) => /^[가-힣]{1,10}$/.test(text);
    const isValidKoreanEnglishNumberText = (text) =>
        /^[가-힣a-zA-Z0-9]{1,10}$/.test(text);

    const isValidFormat = (number) => {
        return /^(\d{1,2}(\.\d)?|100(\.0)?)$/.test(number);
    };
    const isValidDecimal = (number) => {
        // 형식 검증
        if (!isValidFormat(number)) {
            return false;
        }
        return parseFloat(number) <= 100.0;
    };

    // 폼 제출 핸들러
    const handleSubmit = (event) => {
        event.preventDefault();
        //일반회원유효성검사
        if (!checkValidation()) {
            return;
        }

        //form만들기
        const data = new FormData();
        // terminalData의 데이터 추가
        for (const key in formData) {
            data.append(key, formData[key]);
        }

        if (!selectedFiles || !selectedFiles.length || !Array.from(selectedFiles).every(file => file instanceof File)) {
            alert('첨부파일이 선택되지 않았습니다.');
            return;
        } else {
            // selectedFiles가 유효한 파일 배열인 경우의 로직
            Array.from(selectedFiles).forEach((file) => {
                data.append("files", file);
            });
        }

        setLoading(true);
        axios
            .post(`/api/shop/${memberInfo.memCode}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                setLoading(false)
                navigate('/');

            })
            .catch((error) => {
                setLoading(false)
                alert('등록에 실패하였습니다. 잠시후 다시 등록해주세요');
            });
    };


    const checkValidation = () => {
        if (!isValidKoreanName(formData.shopOwner)) {
            alert("대표자명을 확인하세요");
            return false;
        } else if (
            !isValidPhoneNumber(phoneNumber[1]) &&
            !isValidPhoneNumber(phoneNumber[2])
        ) {
            alert("휴대폰번호를 확인하세요");
            return false;
        }
        return true;
    };

    const completeHandler = (data) => {
        if (addrTarget === "addrBtn") {
            formData.shopAddr1 = data.zonecode;
            formData.shopAddr2 = data.roadAddress;
        }
        else {
            formData.postAddr1 = data.zonecode;
            formData.postAddr2 = data.roadAddress;
        }
        handleClose();
    };

    const cancelBtn = () => {
        navigate('/');
    }

    return (
        <>
            <Typography variant="h4" align="center" gutterBottom>
                가맹점 신청
            </Typography>
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h5"
                    style={{
                        borderBottom: "2px solid black",
                        marginBottom: "20px",
                        marginTop: "40px",
                    }}
                >
                    가맹점 정보
                </Typography>
                <Grid container spacing={2}>
                    <BusinessInfo
                        isValidKoreanText={isValidKoreanText}
                        isValidKoreanName={isValidKoreanName}
                        isValidKoreanEnglishNumberText={isValidKoreanEnglishNumberText}
                        handleOpen={handleOpen}
                        formData={formData}
                        handleChange={handleChange}
                        phoneNumber={phoneNumber}
                        phoneNumberHandler={phoneNumberHandler}
                        phoneNumber2={phoneNumber2}
                        phoneNumberHandler2={phoneNumberHandler2}
                        isValidPhoneNumber={isValidPhoneNumber}
                        isValidDecimal={isValidDecimal}
                        bankInfo={bankInfo}
                        setFormData={setFormData}
                        setPhoneNumber2={setPhoneNumber2}

                    ></BusinessInfo>
                </Grid>

                <Typography
                    variant="h5"
                    style={{
                        borderBottom: "2px solid black",
                        marginBottom: "20px",
                        marginTop: "40px",
                    }}
                >
                    서류 첨부
                </Typography>
                <Grid container spacing={2}>
                    <FileInfo
                        fileNameList={fileNameList}
                        handleFileChange={handleFileChange}
                        selectedFiles={selectedFiles}
                    ></FileInfo>
                </Grid>
                <Grid item xs={12} container spacing={2} justifyContent={'center'} alignItems="center">
                    <Grid item xs={5}>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            style={{
                                marginTop: "50px",
                                marginBottom: "50px",
                                backgroundColor: "black",
                            }}
                        >
                            등록
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            type="button"
                            variant="contained"
                            fullWidth
                            style={{
                                marginTop: "50px",
                                marginBottom: "50px",
                                backgroundColor: "black",
                            }}
                            onClick={cancelBtn}
                        >
                            취소
                        </Button>
                    </Grid>

                </Grid>


            </form>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        주소 검색
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        <DaumPostcode onComplete={completeHandler}/>
                    </Typography>
                </Box>
            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
export default SignUpForm;
