import React from "react";
import {
    Card,
    Button,
    TableContainer,
    Table,
    Paper,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import UpdateIcon from '@mui/icons-material/Update';
import CallIcon from '@mui/icons-material/Call';
import {useRecoilValue} from "recoil";
import {memberState} from "../../store/MemberStore";
function MemberCard({memberList, handleOpen}) {
    const memberInfo = useRecoilValue(memberState);


    return (
        <Card>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" component="th" sx={{fontSize: '10px', width: '17%'}}>
                                대표자
                            </TableCell>
                            <TableCell align="center" component="th" sx={{fontSize: '12px'}}>
                                상호명
                            </TableCell>                            
                            <TableCell align="center" component="th" sx={{fontSize: '12px'}}>
                                수수료
                            </TableCell>
                            <TableCell align="center" component="th" sx={{fontSize: '12px'}}>
                                연락처
                            </TableCell>
                            {memberInfo.memRole === 'ROLE_ADMIN' &&
                                <TableCell align="center" component="th" sx={{fontSize: '10px', width: '17%'}}>
                                등록
                            </TableCell>}
                            <TableCell align="center" component="th" sx={{fontSize: '10px'}}>
                                승인
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {memberList.map((member, idx) => (
                            <TableRow key={idx}
                                      onClick={() => {
                                          handleOpen(member)
                                      }}>
                                <TableCell align="center" scope="row" sx={{fontSize: '12px'}}>
                                    {member.shopOwner}
                                </TableCell>
                                <TableCell align="center" sx={{fontSize: '10px'}}>
                                    {member.shopTitle}
                                </TableCell>
                                <TableCell align="center" sx={{fontSize: '10px'}}>
                                    {member.shopCharge + '%'}
                                </TableCell>
                                <TableCell align="center" sx={{fontSize: '9px'}} >
                                    {member.shopTell}
                                </TableCell>
                                {memberInfo.memRole === 'ROLE_ADMIN' &&
                                    <TableCell align="center" sx={{fontSize: '9px'}}>
                                    {member.memName}
                                </TableCell>
                                }
                                <TableCell align="center" sx={{fontSize: '9px'}}>
                                    {member.permit === '대기' ?
                                        <UpdateIcon sx={{color: '#ff9100'}}></UpdateIcon>
                                        : member.permit === '승인' ?
                                            <CheckIcon color="success"></CheckIcon>
                                            :
                                            <CancelIcon sx={{color: '#ff0000'}}></CancelIcon>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
}

export default MemberCard;
