import {Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React from "react";

export const PostInfo = ({formData, handleChange, isValidKoreanName, phoneNumber2, phoneNumberHandler2, handleOpen, isValidPhoneNumber}) => {

    return (
        <>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="이름"
                    name="postName"
                    value={formData.postName}
                    onChange={handleChange}
                    variant="standard"
                    error={
                        !isValidKoreanName(formData.postName) &&
                        formData.postName !== ""
                    }
                    helperText={
                        !isValidKoreanName(formData.postName) &&
                        formData.postName !== ""
                            ? "2~4자리 한글"
                            : ""
                    }
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth variant="standard">
                    <InputLabel>휴대전화</InputLabel>
                    <Select
                        name="postTellFirst"
                        value={phoneNumber2[0]}
                        onChange={(event) => {
                            phoneNumberHandler2(event, 0);
                        }}
                    >
                        <MenuItem value="010">010</MenuItem>
                        <MenuItem value="011">011</MenuItem>
                        <MenuItem value="016">016</MenuItem>
                        <MenuItem value="016">017</MenuItem>
                        <MenuItem value="019">019</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="중간자리"
                    name="postTellSecond"
                    value={phoneNumber2[1]}
                    onChange={(event) => {
                        phoneNumberHandler2(event, 1);
                    }}
                    variant="standard"
                    error={
                        !isValidPhoneNumber(phoneNumber2[1]) &&
                        phoneNumber2[1] !== ""
                    }
                    helperText={
                        !isValidPhoneNumber(phoneNumber2[1]) &&
                        phoneNumber2[1] !== ""
                            ? "3~4자리 숫자"
                            : ""
                    }
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="뒷자리"
                    name="postTellThird"
                    value={phoneNumber2[2]}
                    onChange={(event) => {
                        phoneNumberHandler2(event, 2);
                    }}
                    variant="standard"
                    error={
                        !isValidPhoneNumber(phoneNumber2[2]) &&
                        phoneNumber2[2] !== ""
                    }
                    helperText={
                        !isValidPhoneNumber(phoneNumber2[2]) &&
                        phoneNumber2[2] !== ""
                            ? "3~4자리 숫자"
                            : ""
                    }
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="우편번호"
                    name="postAddr1"
                    value={formData.postAddr1}
                    onChange={handleChange}
                    variant="standard"
                    disabled
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                    InputProps={{
                        sx: {
                            '::placeholder': { // placeholder 스타일 적용
                                fontSize: '12px', // 원하는 글자 크기로 설정
                            },
                            input: { // input 필드 스타일
                                fontSize: '12px', // 입력 텍스트 글자 크기도 조절할 수 있습니다
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item xs={5}>
                <TextField
                    fullWidth
                    label="주소"
                    name="postAddr2"
                    value={formData.postAddr2}
                    onChange={handleChange}
                    variant="standard"
                    disabled
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                    InputProps={{
                        sx: {
                            '::placeholder': { // placeholder 스타일 적용
                                fontSize: '12px', // 원하는 글자 크기로 설정
                            },
                            input: { // input 필드 스타일
                                fontSize: '12px', // 입력 텍스트 글자 크기도 조절할 수 있습니다
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item xs={4} sx={{paddingRight: 1,}}>
                <Button
                    fullWidth
                    name="postBtn"
                    variant="outlined"
                    size={'small'}
                    style={{
                        borderColor: 'black',
                        color: "black",
                        fontSize: 9,
                        marginTop: '20px',
                        verticalAlign: 'bottom',
                    }}
                    onClick={handleOpen}
                >
                    검색
                </Button>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="상세주소"
                    name="postAddrDetail"
                    value={formData.postAddrDetail}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>

        </>
    )
}
