import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import axios from '../axiosInstance';
import {useState} from "react";
import {memberState} from "../store/MemberStore";
import {useRecoilState} from "recoil";
import {useNavigate} from "react-router-dom";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit">
                LastP
            </Link>{' '}
            {2024}
            {'.'}
        </Typography>
    );
}


export default function Login() {

    const navigate = useNavigate();
    const [memberInfo, setMemberInfo] = useRecoilState(memberState);
    const [formData, setFormData] = useState({
        memId: "",
        memPw: "",
    });

    const handleChange = (e) => {
        const field = e.target.name;

        if (field === 'dType') {
            setFormData({...formData, shopNum: '', birthday: '', [field]: e.target.value})
            return
        }
        setFormData({...formData, [field]: e.target.value});
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
        axios.post('/api/auth/login', data).then((response) => {
            saveToken(response.data);
            setMemberInfo({memCode: response.data.memCode, memRole: response.data.memRole})
            navigate('/');
        }).catch(err => {
            console.error(err)
            alert('아이디 혹은 비밀번호를 확인하세요')
        })

    }
    // const saveToken = (data) => {
    //     sessionStorage.setItem('accessToken', data.accessToken);
    //     localStorage.setItem('refreshToken', data.refreshToken);
    // }

    const saveToken = (data) => {
        document.cookie = `accessToken=${data.accessToken}; path=/;`;
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1); // 현재로부터 1년 후
        document.cookie = `refreshToken=${data.refreshToken}; path=/; expires=${expires.toUTCString()};`;
    }

    return (
        <>
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor: 'black'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    로그인
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="memId"
                        label="ID"
                        name="memId"
                        autoFocus
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="memPw"
                        label="Password"
                        type="password"
                        id="memPw"
                        autoComplete="current-password"
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2, backgroundColor: 'black', color: 'white'}}
                    >
                        Login
                    </Button>
                </Box>
            </Box>
            <Copyright sx={{mt: 8, mb: 4}}/>
        </>
    );
}