import {
    Accordion, AccordionDetails,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import ImageViewer from 'react-simple-image-viewer';
import CallIcon from "@mui/icons-material/Call";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import UpdateIcon from "@mui/icons-material/Update";
import {useRecoilValue} from "recoil";
import {memberState} from "../../store/MemberStore";
import axios from "../../axiosInstance";

export const BasicInfoModal = ({data, handleClose}) => {

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [permitState, setPermitState] = useState(data.permit);
    const [shopMemoText, setShopMemoText] = useState("");
    const memberInfo = useRecoilValue(memberState);

    // 이미지 URL 배열 생성
    // const images = data.joinFiles.map(file => `https://www.upup.kr/files/${file.attachedName}`);
    const images = data.joinFiles.map(file => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.attachedName}`;
    });

    const imageURL = ((file) => {
        const currentDomain = `${window.location.protocol}//${window.location.host}`;
        return `${currentDomain}/files/${file.attachedName}`;
    });

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const shopMemoHandler = (event) => {
        setShopMemoText(event.target.value);
    }

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const permitStateHandler = (event, newValue) => {
        setPermitState(newValue);
    }

    const patchPermit = () => {

        if (permitState === '반려' && (shopMemoText === null || shopMemoText === '')) {
            alert('반려 사유를 입력해주세요');
            return
        } else {
            setShopMemoText(" ");
        }

        const payload = new FormData();
        payload.append('permit', permitState);
        payload.append('shopMemo', shopMemoText);

        axios.patch(`/api/shop/${data.shopCode}`, payload).then(() => {
            alert(permitState + '상태로 변경되었습니다.');
            handleClose();
        });
    }

    const [openAccordions, setOpenAccordions] = useState([]); // 여러 아코디언의 상태를 배열로 관리

    const handleAccordion = (index) => {
        setOpenAccordions(prevOpen => {
            if (prevOpen.includes(index)) {
                // 이미 열린 아코디언을 닫습니다.
                return prevOpen.filter(i => i !== index);
            } else {
                // 새 아코디언을 추가합니다.
                return [...prevOpen, index];
            }
        });
    };

    const removeHyphens = (phoneNumber) => {
        return phoneNumber.replace(/-/g, '');
    }


    return (
        <>
            <Table aria-label="simple table">
                <TableBody>
                    {memberInfo.memRole === 'ROLE_ADMIN' &&
                        <TableRow>
                            <TableCell colspan={1} align="center" sx={{width: '25%', paddingX: 0}}>
                                <ToggleButtonGroup
                                    value={permitState}
                                    onChange={permitStateHandler}
                                    exclusive
                                    aria-label="text alignment"
                                    size="small"
                                >
                                    <ToggleButton value="승인" aria-label="right aligned">
                                        <CheckIcon fontSize={'small'} color="success"></CheckIcon>
                                    </ToggleButton>
                                    <ToggleButton value="대기" aria-label="centered">
                                        <UpdateIcon fontSize={'small'} sx={{color: '#ff9100'}}></UpdateIcon>
                                    </ToggleButton>
                                    <ToggleButton value="반려" aria-label="left aligned">
                                        <CancelIcon fontSize={'small'} fontSize={'small'}
                                                    sx={{color: '#ff0000'}}></CancelIcon>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </TableCell>
                            <TableCell colSpan={2} align="center" sx={{width: '60%', verticalAlign: 'bottom'}}>
                                <TextField
                                    size="small"
                                    placeholder="반려사유"
                                    fullWidth
                                    name="shopMemo"
                                    variant="standard"
                                    onChange={shopMemoHandler}
                                    required
                                />
                            </TableCell>
                            <TableCell colSpan={1} align="center" sx={{paddingRight: 0}}>
                                <Button
                                    onClick={patchPermit}
                                    type={'button'}
                                    variant="outlined"
                                    size={'small'}
                                    style={{
                                        borderColor: 'black',
                                        color: "black",
                                        fontSize: 12,
                                    }}>변경</Button>
                            </TableCell>
                        </TableRow>}
                </TableBody>
            </Table>
            <Table aria-label="simple table">
                <TableBody>
                    {data.permit === '반려' &&
                        <TableRow>
                            <TableCell colSpan={2} align="center"
                                       sx={{bgcolor: 'grey.200', width: '25%'}}>반려사유</TableCell>
                            <TableCell colSpan={2} align="center">{data.shopMemo}</TableCell>
                        </TableRow>
                    }
                    <TableRow>
                        <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200'}}>대표자명</TableCell>
                        <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200',}}>상호명</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align="center">{data.shopOwner}</TableCell>
                        <TableCell colSpan={2} align="center">{data.shopTitle}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align="center" scope="row" sx={{bgcolor: 'grey.200'}}>
                            연락처
                        </TableCell>
                        <TableCell colSpan={2} align="center" scope="row" sx={{bgcolor: 'grey.200'}}>
                            수수료<span style={{fontSize: '9px'}}> ( VAT 포함 )</span>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align="center">{removeHyphens(data.shopTell)}
                        </TableCell>
                        <TableCell colSpan={2} align="center">{data.shopCharge + '%'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4} align="center" scope="row" sx={{bgcolor: 'grey.200'}}>
                            주소
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4}
                                   align="center">
                            {data.shopAddr1 + ' ' + data.shopAddr2 + ' ' + data.shopAddrDetail}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200'}}>업종</TableCell>
                        <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200'}}>업태</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align="center">{data.shopType}</TableCell>
                        <TableCell colSpan={2} align="center">{data.shopItem}</TableCell>
                    </TableRow>
                    {memberInfo.memRole === 'ROLE_ADMIN' ?
                        <>
                            <TableRow>
                                <TableCell colSpan={1} align="center" sx={{bgcolor: 'grey.200'}}>은행명</TableCell>
                                <TableCell colSpan={1} align="center" sx={{bgcolor: 'grey.200'}}>코드</TableCell>
                                <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200'}}>예금주</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={1} align="center">{data.shopBankName}</TableCell>
                                <TableCell colSpan={1} align="center">{data.bankCode}</TableCell>
                                <TableCell colSpan={2} align="center">{data.shopBankOwner}</TableCell>

                            </TableRow>
                        </>
                        :
                        <>
                            <TableRow>
                                <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200'}}>은행명</TableCell>
                                <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200'}}>예금주</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} align="center">{data.shopBankName}</TableCell>
                                <TableCell colSpan={2} align="center">{data.shopBankOwner}</TableCell>
                            </TableRow>
                        </>

                    }

                    {/*대리점 정보*/}
                    <TableRow>
                        <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200'}}>총판</TableCell>
                        <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200',}}>대리점</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align="center">{data.lv4}</TableCell>
                        <TableCell colSpan={2} align="center">{data.lv5}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200'}}>영업자</TableCell>
                        <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200',}}>정산방식</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align="center">{data.lv6}</TableCell>
                        <TableCell colSpan={2} align="center">{data.method === '1' ? '자동 정산' : data.method === '2' ? '지갑 정산' : ''}</TableCell>
                    </TableRow>

                    {/*계좌정보*/}

                    <TableRow>
                        <TableCell colSpan={4} align="center" sx={{bgcolor: 'grey.200'}}>계좌번호</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4} align="center">{data.shopBankNum}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={4} align="center" sx={{bgcolor: 'grey.200'}}>배송정보</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200'}}>이름</TableCell>
                        <TableCell colSpan={2} align="center" sx={{bgcolor: 'grey.200',}}>연락처</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align="center">{data.postName}</TableCell>
                        <TableCell colSpan={2} align="center">{data.postTell}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={4} align="center" sx={{bgcolor: 'grey.200'}}>배송지</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4} align="center">
                            {data.postAddr1 + ' ' + data.postAddr2 + ' ' + data.postAddrDetail}
                        </TableCell>
                    </TableRow>


                    <TableRow>
                        <TableCell colSpan={4} align="center" sx={{bgcolor: 'grey.200'}}>메모</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4} align="center">{data.memo}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4} align="center" scope="row" sx={{bgcolor: 'grey.200'}}>
                            첨부서류
                        </TableCell>
                    </TableRow>
                    {data.joinFiles.map((file, index) => (
                        <>
                            <TableRow onClick={() => handleAccordion(index)} key={file.memCode}>
                                <TableCell colspan={4} align="center">{file.originName}</TableCell>
                            </TableRow>
                            {openAccordions.includes(index) && (
                                <TableRow>
                                    <TableCell colspan={4}>
                                        <AccordionDetails>
                                            <img src={imageURL(file)} alt={file.originName}
                                                 style={{maxWidth: '100%', height: 'auto'}}
                                                 onClick={() => openImageViewer(index)}/>
                                        </AccordionDetails>
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    ))}
                </TableBody>
            </Table>
            {
                isViewerOpen && (
                    <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
                )
            }
        </>
    )
}
