import {Typography, Grid, TextField, Button} from "@mui/material";
import React, {useState} from "react";
import {useRecoilValue} from "recoil";
import {memberState} from "../store/MemberStore";
import axios from "../axiosInstance";
import {useNavigate} from "react-router-dom";

export const ChangePw = () => {

    const memberInfo = useRecoilValue(memberState);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        memPw: "",
        memPwConfirm: "",
    });

    const handleChange = (e) => {
        const field = e.target.name;
        setFormData({...formData, [field]: e.target.value});
    };

    const isValidPassword = (password) =>
        /^[A-Za-z\d@$!%*?&]{4,}$/.test(password);

    const logoutHandler = () => {
        const refreshToken = localStorage.getItem('refreshToken');
        axios.delete('/api/auth/logout', { data: { refreshToken } }).then(() => {
            document.cookie = 'refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
            document.cookie = 'accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
            navigate('/login');
        })
    }

    const updatePassword = () => {

        const data = {
            memPw: formData.memPw
        };
        axios.patch(`/api/shop/reset/${memberInfo.memCode}`, data).then((response) => {
            alert('비밀번호 변경 완료 로그인 화면으로 이동합니다.')
            logoutHandler()
        })
    }

    return (
        <>
            <Typography variant="h6" align="center" gutterBottom>
                비밀번호변경
            </Typography>
            <Grid item xs={12} container spacing={2} justifyContent={'center'} alignItems="center">
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        label="비밀번호"
                        name="memPw"
                        type="password"
                        value={formData.memPw}
                        onChange={handleChange}
                        variant="standard"
                        autoComplete="new-password"
                        required
                        error={
                            !isValidPassword(formData.memPw) &&
                            formData.memPw !== ""
                        }
                        helperText={
                            !isValidPassword(formData.memPw) &&
                            formData.memPw !== ""
                                ? "최소 4자 이상"
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        label="비밀번호 확인"
                        name="memPwConfirm"
                        type="password"
                        value={formData.memPwConfirm}
                        onChange={handleChange}
                        variant="standard"
                        autoComplete="new-password"
                        required
                        error={
                            formData.memPw !== formData.memPwConfirm &&
                            formData.memPwConfirm !== ""
                        }
                        helperText={
                            formData.memPw !== formData.memPwConfirm &&
                            formData.memPwConfirm !== ""
                                ? "비밀번호가 일치하지 않습니다."
                                : ""
                        }
                    />
                </Grid>
                <Grid item xs={8}>
                    <Button
                        onClick={updatePassword}
                        type="button"
                        variant="contained"
                        fullWidth
                        style={{
                            backgroundColor: "black",
                            fontSize: 14,
                        }}
                    >
                        변경
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}
