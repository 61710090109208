import {Button, Grid, Table, TableBody, TableCell, TableRow, TextField} from "@mui/material";
import React from "react";

export default function FileInfo({
                                     fileNameList,
                                     selectedFiles,
                                     handleFileChange,
                                 }) {
    return (
        <>
            <Grid item container spacing={2} xs={12}>
                <Table aria-label="simple table">
                    <TableBody>
                {fileNameList.map((fileName, index) =>  (
                        <>
                            {/*<Grid*/}
                            {/*    item*/}
                            {/*    xs={3}*/}
                            {/*    key={index}*/}
                            {/*    container*/}
                            {/*    justifyContent="center"*/}
                            {/*    alignItems="center"*/}
                            {/*>*/}
                            {/*    <input*/}
                            {/*        accept="image/*"*/}
                            {/*        style={{display: "none"}}*/}
                            {/*        id={`raised-button-file-${index}`}*/}
                            {/*        type="file"*/}
                            {/*        name={name}*/}
                            {/*        onChange={handleFileChange(index)}*/}
                            {/*    />*/}
                            {/*    <label htmlFor={`raised-button-file-${index}`}*/}
                            {/*           style={{textAlign: "center", display: "inline-block"}}>*/}
                            {/*            <span>*/}
                            {/*                    <>*/}
                            {/*                        <br/>*/}
                            {/*                        <span style={{fontSize: "9px"}}>*/}
                            {/*                            {name}*/}
                            {/*                        </span>*/}
                            {/*                    </>*/}
                            {/*            </span>*/}
                            {/*    </label>*/}
                            {/*</Grid>*/}

                            <TableRow key={index}>
                                <TableCell component="th" align="center" component="th"
                                           sx={{fontSize: '10px', width: '65%'}}>
                                    {fileName}
                                </TableCell>
                                <TableCell align="center" component="th" sx={{fontSize: '12px'}}>
                                    <input
                                        accept="image/*"
                                        style={{display: "none"}}
                                        id={`edit-file-${index}`}
                                        type="file"
                                        name={fileName}
                                        onChange={handleFileChange(index)}
                                    />
                                    <label htmlFor={`edit-file-${index}`}
                                           style={{textAlign: "center", display: "inline-block"}}>
                                        <Button
                                            disabled
                                            size="small"
                                            variant="contained"
                                            style={{
                                                backgroundColor: "black",
                                                fontSize: 9,
                                                color: 'white'
                                            }}
                                        >등록</Button>
                                    </label>
                                </TableCell>
                            </TableRow>
                        </>
                ))
                }
                    </TableBody>
                </Table>
            </Grid>
            {selectedFiles.map((fileName, index) => (
                <Grid item xs={12} key={index}>
                    <TextField
                        fullWidth
                        variant="standard"
                        margin="normal"
                        disabled
                        label={`선택된 파일 ${index + 1}`}
                        value={fileName.name}
                        InputLabelProps={{shrink: true}}
                    />
                </Grid>
            ))}
        </>
    );
}
