import axios from "../axiosInstance";
import {
    Pagination, PaginationItem,
    Grid, Switch, Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography, Modal, Box
} from "@mui/material";
import React, {useEffect, useState} from "react";
import resetIcon from '../image/resetIcon.png'
import CloseIcon from "@mui/icons-material/Close";
import {RegIdForm} from "./components/RegIdForm";
import {EditId} from "./components/EditId";

export const JoinId = () => {

    const [formData, setFormData] = useState({
        memRole: "",
        memName: "",
        memMemo: "",
        memId: "",
        memPw: "1234",
        memTell: "",
        bankName: "",
        bankNum: "",
        bankOwner: ""
    });
    const [idCheck, setIdCheck] = useState(false);
    const [idList, setIdList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const [phoneNumber, setPhoneNumber] = useState(["010", "", ""]);
    const [bankInfo, setBankInfo] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState({});

    useEffect(() => {
        getIdList();
        getBankInfo();
    }, []);

    useEffect(() => {
        setIdCheck(false);
    }, [formData.memId]);

    useEffect(() => {
        getIdList();
    }, [page]);


    const handleOpen = (data) => {
        setSelectedMember({...data})
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedMember({})
        getIdList()
    };


    const getBankInfo = () => {
        axios.get('/api/shop/bankList').then(response => {
            setBankInfo(response.data);
        })
    }

    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };

    const valid = () => {
        if (!isValidId(formData.memId)) {
            return true;
        }
        // if(!isValidPassword(formData.memPw)){
        //     return true;
        // }
        // if(formData.memPw !== formData.memPwConfirm){
        //     return true;
        // }
        if (!isValidKoreanEnglishNumberText(formData.memName)) {
            return true;
        }

        return false;
    }
    const isValidKoreanEnglishNumberText = (text) =>
        /^[가-힣a-zA-Z0-9]{1,10}$/.test(text);

    const isValidId = (id) => /^[a-zA-Z0-9]{4,}$/.test(id);
    // 비밀번호 유효성 검사: 최소 8자 이상, 숫자, 문자, 특수문자 각각 최소 1개 포함
    // const isValidPassword = (password) =>
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
    //         password
    //     );
    const handleChange = (e) => {
        const field = e.target.name;
        setFormData({...formData, [field]: e.target.value});
    };
    const isValidPhoneNumber = (number) => /^\d{3,4}$/.test(number);

    const phoneNumberHandler = (e, idx) => {
        const newPhoneNumber = [...phoneNumber];
        newPhoneNumber[idx] = e.target.value;
        setPhoneNumber(newPhoneNumber);
        setFormData({
            ...formData,
            memTell: `${newPhoneNumber[0]}-${newPhoneNumber[1]}-${newPhoneNumber[2]}`,
        });
    };


    const checkDuplicateId = () => {
        if (formData.memId === '') {
            alert('ID를 입력하세요');
            return
        }
        axios.get(`/api/admin/checkId/${formData.memId}`).then(response => {
            response.data === 0 ? setIdCheck(true) : setIdCheck(false)
            response.data === 0 ? alert("사용가능한 아이디 입니다.") : alert("이미 사용중인 아이디 입니다.")
        });
    }

    const getIdList = () => {
        axios.get('/api/admin/members?page=' + page).then(response => {
            setIdList(response.data.content);
            setPageData(response.data);
        })
    }

    const resetPW = (memCode, memName) => {

        if (!window.confirm(memName + ' 회원의 비밀번호를 초기화 하시겠습니까?')) {
            return;
        }

        axios.patch(`/api/admin/reset/${memCode}`).then(response => {
            alert('비밀번호가 초기화되었습니다.');
        }).catch(error => {
            console.error('비밀번호 초기화 중 오류 발생:', error);
            alert('비밀번호 초기화에 실패했습니다.');
        });
    }
    const handleSwitchChange = (memCode, isChecked) => {
        const isUse = isChecked ? 'Y' : 'N';
        const data = new FormData();
        data.append('isUse', isUse);
        axios.patch(`/api/admin/${memCode}`, data).then((response) => {
            getIdList();
        })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (valid()) {
            return;
        }
        if (!idCheck) {
            alert("ID 중복검사를 먼저 해주세요.")
            return
        }

        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
        axios
            .post("/api/admin/join", data)
            .then((response) => {
                alert('아이디가 생성되었습니다.')
                getIdList();
            })
            .catch((error) => {
                alert('아이디가 생성에 실패했습니다.')
            });
    };

    return (
        <>
            <Typography variant="h6" align="center" gutterBottom>
                영업자 ID 생성
            </Typography>
            <RegIdForm handleSubmit={handleSubmit} formData={formData}
                       isValidKoreanEnglishNumberText={isValidKoreanEnglishNumberText}
                       handleChange={handleChange} isValidId={isValidId}
                       phoneNumber={phoneNumber} phoneNumberHandler={phoneNumberHandler}
                       isValidPhoneNumber={isValidPhoneNumber} bankInfo={bankInfo}
                       checkDuplicateId={checkDuplicateId}

            ></RegIdForm>

            <Grid item xs={12} container spacing={2} justifyContent={'center'} alignItems="center">
                <Grid item xs={12}>
                    <TableContainer sx={{marginTop: 2, maxWidth: '100%'}}>
                        <Typography variant="h6" align="center" gutterBottom>
                            영업자 목록
                        </Typography>
                        <Table aria-label="simple table">
                            <TableHead sx={{bgcolor: '#f0f0f0'}}>
                                <TableRow>
                                    <TableCell align="center" sx={{fontSize: '10px', padding: 0 }}>영업자</TableCell>
                                    <TableCell align="center" sx={{fontSize: '10px', padding: 0 }}>ID</TableCell>
                                    <TableCell align="center" sx={{fontSize: '10px', padding: 0 }}>연락처</TableCell>
                                    <TableCell align="center" sx={{fontSize: '10px', padding: 0 }}>메모</TableCell>
                                    <TableCell align="center" sx={{fontSize: '10px', padding: 0 }}>로그인 <br/> 허용</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {idList.length > 0 &&
                                    idList.map((id, index) => (
                                        <React.Fragment key={id.memCode + index}>
                                            <TableRow sx={{borderTop: '2px solid black'}}>
                                                <TableCell align="center" sx={{
                                                    fontSize: '10px',
                                                    borderLeft: '2px solid black'
                                                }}>{id.memName}</TableCell>
                                                <TableCell align="center" sx={{fontSize: '10px'}}>{id.memId}</TableCell>
                                                <TableCell align="center"
                                                           sx={{fontSize: '10px'}}>{id.memTell}</TableCell>
                                                <TableCell align="center"
                                                           sx={{fontSize: '10px'}}>{id.memMemo}</TableCell>
                                                <TableCell align="center"
                                                           sx={{fontSize: '10px', borderRight: '2px solid black'}}>
                                                    <Switch
                                                        checked={id.isUse === 'Y'}
                                                        onChange={(e) => handleSwitchChange(id.memCode, e.target.checked)}
                                                        size="small"
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            {(id.bankName !== null && id.bankName !== '') ?

                                                <TableRow sx={{borderBottom: '2px solid black'}}>
                                                    <TableCell align="center"
                                                               colSpan={1}
                                                               sx={{fontSize: '10px', textAlign: 'center', borderLeft: '2px solid black'}}>
                                                    </TableCell>
                                                    <TableCell align="center"
                                                               colSpan={1}
                                                               sx={{
                                                                   fontSize: '10px',
                                                                   textAlign: 'center'
                                                               }}>
                                                        {id.bankOwner}
                                                       </TableCell>
                                                    <TableCell align="center"
                                                               colSpan={1}
                                                               sx={{fontSize: '10px', textAlign: 'center'}}>
                                                        {(id.bankName !== null && id.bankName !== '') ? id.bankName + ' (' + id.bankCode + ')' : ''}
                                                    </TableCell>
                                                    <TableCell colSpan={1} align="center"
                                                               sx={{
                                                                   fontSize: '10px',
                                                                   textAlign: 'center',
                                                               }}>{id.bankNum}</TableCell>
                                                    <TableCell sx={{
                                                        fontSize: '10px',
                                                        textAlign: 'center',
                                                        borderRight: '2px solid black'
                                                    }} colSpan={1} onClick={() => {
                                                        handleOpen(id)
                                                    }}>
                                                        수정
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                <TableRow sx={{borderBottom: '2px solid black'}}
                                                >
                                                    <TableCell sx={{
                                                        fontSize: '10px',
                                                        borderLeft: '2px solid black',
                                                        textAlign: 'center',
                                                    }} colSpan={4}>
                                                        등록된 계좌정보가 없습니다.
                                                    </TableCell>
                                                    <TableCell sx={{
                                                        fontSize: '10px',
                                                        textAlign: 'center',
                                                        borderRight: '2px solid black'
                                                    }} colSpan={1} onClick={() => {
                                                        handleOpen(id)
                                                    }}>
                                                        수정
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </React.Fragment>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        count={pageData.pageSize > 0 ? Math.ceil(pageData.totalElements / pageData.pageSize) : 0}
                        page={pageData.currentPage || 1}
                        onChange={onPageChange}
                        size="medium"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "15px 0",
                        }}
                        renderItem={(item) => (
                            <PaginationItem {...item} sx={{fontSize: 12}}/>
                        )}
                    />

                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        position: 'relative', // 상대적 위치 지정
                        maxWidth: '600px',
                        minWidth: '300px',
                        width: '45%',
                        maxHeight: '80%',
                        overflowY: 'scroll',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 3,
                    }}
                >
                    <CloseIcon
                        sx={{
                            position: 'absolute', // 절대적 위치 지정
                            top: 0, // 상단에 위치
                            right: 0, // 우측에 위치
                            cursor: 'pointer' // 마우스 오버 시 포인터 변경
                        }}
                        onClick={handleClose} // 클릭 시 닫기 이벤트
                    />
                    <EditId handleClose={handleClose} getIdList={getIdList} bankInfo={bankInfo}
                            selectedMember={selectedMember}></EditId>
                </Box>
            </Modal>
        </>
    )
}
