import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "../../axiosInstance";

export const EditId = ({selectedMember, bankInfo, handleClose}) => {

    const [phoneNumber, setPhoneNumber] = useState(["010", "", ""]);
    const [formData, setFormData] = useState({
        memMemo: "",
        memTell: "",
        bankName: "",
        bankNum: "",
        bankOwner: ""
    });

    useEffect(() => {
        setFormData({...selectedMember})
        splitAndSetPhoneNumber()
    }, []);

    useEffect(() => {
    }, [formData]);

    const resetPW = (memCode, memName) => {

        if (!window.confirm(memName + ' 회원의 비밀번호를 초기화 하시겠습니까?')) {
            return;
        }

        axios.patch(`/api/admin/reset/${memCode}`).then(response => {
            alert('비밀번호가 초기화되었습니다.');
        }).catch(error => {
            console.error('비밀번호 초기화 중 오류 발생:', error);
            alert('비밀번호 초기화에 실패했습니다.');
        });
    }


    const updateMember = () => {
        const data = new FormData();
        // terminalData의 데이터 추가
        for (const key in formData) {
            if (key !== 'memCode' && key !== 'memId' && key !== 'memName'){
                data.append(key, formData[key]);
            }
        }

        axios.patch(`/api/admin/update/${formData.memCode}`, data).then((response) => {
            alert('영업자 정보 수정 완료')
            handleClose()
        })
    }

    const splitAndSetPhoneNumber = () => {
        // 문자열을 '-' 기준으로 분리
        const splitNumbers = selectedMember.memTell.split('-');
        // // 전화번호 배열 업데이트
        setPhoneNumber(splitNumbers);
    };

    const phoneNumberHandler = (e, idx) => {
        const newPhoneNumber = [...phoneNumber];
        newPhoneNumber[idx] = e.target.value;
        setPhoneNumber(newPhoneNumber);
        setFormData({
            ...formData,
            memTell: `${newPhoneNumber[0]}-${newPhoneNumber[1]}-${newPhoneNumber[2]}`,
        });
    };

    const isValidPhoneNumber = (number) => /^\d{3,4}$/.test(number);

    const handleChange = (e) => {
        const field = e.target.name;
        setFormData({...formData, [field]: e.target.value});
    };

    return (
        <>
            <Typography variant="h5" borderBottom="2px solid black" sx={{ marginBottom: '20px' }}>
               영업자 정보 수정
            </Typography>
            <Grid item xs={12} container spacing={2} justifyContent={'center'} alignItems="center">
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        label={'영업자명'}
                        name="memName"
                        value={formData.memName}
                        onChange={handleChange}
                        variant="standard"
                        disabled
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        label={'영업자ID'}
                        name="memId"
                        value={formData.memId}
                        variant="standard"
                        disabled
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel>휴대전화</InputLabel>
                        <Select
                            name="memTellFirst"
                            value={phoneNumber[0]}
                            onChange={(event) => {
                                phoneNumberHandler(event, 0);
                            }}
                        >
                            <MenuItem value="010">010</MenuItem>
                            <MenuItem value="011">011</MenuItem>
                            <MenuItem value="016">016</MenuItem>
                            <MenuItem value="016">017</MenuItem>
                            <MenuItem value="019">019</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label="중간자리"
                        name="memTellSecond"
                        value={phoneNumber[1]}
                        onChange={(event) => {
                            phoneNumberHandler(event, 1);
                        }}
                        variant="standard"
                        required
                        error={
                            !isValidPhoneNumber(phoneNumber[1]) &&
                            phoneNumber[1] !== ""
                        }
                        helperText={
                            !isValidPhoneNumber(phoneNumber[1]) &&
                            phoneNumber[1] !== ""
                                ? "3~4자리 숫자"
                                : ""
                        }
                        InputLabelProps={{
                            style: {fontSize: '12px'}
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="뒷자리"
                        name="memTellThird"
                        value={phoneNumber[2]}
                        onChange={(event) => {
                            phoneNumberHandler(event, 2);
                        }}
                        variant="standard"
                        required
                        error={
                            !isValidPhoneNumber(phoneNumber[2]) &&
                            phoneNumber[2] !== ""
                        }
                        helperText={
                            !isValidPhoneNumber(phoneNumber[2]) &&
                            phoneNumber[2] !== ""
                                ? "3~4자리 숫자"
                                : ""
                        }
                        InputLabelProps={{
                            style: {fontSize: '12px'}
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <InputLabel sx={{fontSize: '12px'}}>은행명</InputLabel>
                    <Select
                        size="small"
                        name="bankName"
                        onChange={handleChange}
                        value={formData.bankName}
                        fullWidth
                        variant="standard"
                    >
                        {bankInfo.map((bank, index) => (
                            <MenuItem key={bank.BANK_NAME + index}
                                      value={bank.BANK_NAME}>{bank.BANK_NAME}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        label="예금주"
                        name="bankOwner"
                        value={formData.bankOwner}
                        onChange={handleChange}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        label="계좌번호"
                        name="bankNum"
                        value={formData.bankNum}
                        onChange={handleChange}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        label="메모"
                        name="memMemo"
                        type="text"
                        value={formData.memMemo}
                        onChange={handleChange}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={5}>
                    <Button
                        type="submit"
                        variant="contained"
                        size={'small'}
                        fullWidth
                        style={{
                            minHeight: '33px',
                            backgroundColor: "black",
                            fontSize: 12,
                        }}
                        onClick={ () => {
                            resetPW(selectedMember.memCode, selectedMember.memName)
                        }}
                    >
                        비밀번호 초기화
                    </Button>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        onClick={updateMember}
                        style={{
                            minHeight: '33px',
                            backgroundColor: "black",
                            fontSize: 14,
                        }}
                        size={'small'}
                    >
                        수정
                    </Button>
                </Grid>
            </Grid>


        </>
    )
}
