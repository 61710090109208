 import {
    Box,
    Button,
    Grid,
    Paper, Table, TableBody, TableCell,
    TableContainer, TableRow, TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {BasicInfoModal} from "./BasicInfoModal";
import axios from "../../axiosInstance";
import BusinessInfo from "./BusinessInfo";
import Modal from "@mui/material/Modal";
import DaumPostcode from "react-daum-postcode";
import * as PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import UpdateIcon from "@mui/icons-material/Update";
 import {useRecoilValue} from "recoil";
 import {memberState} from "../../store/MemberStore";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '85%',
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
};


Table.propTypes = {
    container: PropTypes.bool,
    spacing: PropTypes.number,
    sx: PropTypes.shape({marginTop: PropTypes.number}),
    children: PropTypes.node
};
export default function MemberModal({data, handleClose, setLoading}) {

    const navigate = useNavigate();
    const memberInfo = useRecoilValue(memberState);
    const [formData, setFormData] = useState({...data});
    const [phoneNumber, setPhoneNumber] = useState(["010", "", ""]);
    const [phoneNumber2, setPhoneNumber2] = useState(["010", "", ""]);
    const [addrTarget, setAddressTarget] = useState();
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileNameList = [
        '신분증(사업자등록증)',
        "간판사진",
        "매장사진1",
        "매장사진2",
        "통장사본",
        "단말기 시리얼"
    ];
    const [bankInfo, setBankInfo] = useState([]);

    useEffect(() => {
        getBankInfo();
    }, []);

    useEffect(() => {
        setFormData({...data})
        splitAndSetPhoneNumber();
    }, [data]);



    const getBankInfo = () => {
        axios.get('/api/shop/bankList').then(response => {
            setBankInfo(response.data);
        })
    }

    const splitAndSetPhoneNumber = () => {
        // 문자열을 '-' 기준으로 분리
        const splitNumbers = data.shopTell.split('-');
        const splitNumbers2 = data.postTell.split('-');

        // 전화번호 배열 업데이트
        setPhoneNumber(splitNumbers);
        setPhoneNumber2(splitNumbers2);

        // 필요한 경우 formData 업데이트
        setFormData({
            ...formData,
            shopTell: data.shopTell,
            postTell: data.postTell
        });
    };
    const editHandler = () => {
        setTimeout(() => {
            setIsEdit(!isEdit);
        }, 150); // 0.3초 후에 실행

    }
    const deleteHandler = () => {
        axios.delete(`/api/shop/${data.shopCode}`).then(() => (
            handleClose()
        ))
    }

    const patchHandler = (event) => {
        event.preventDefault();
        //일반회원유효성검사
        if (!checkValidation()) {
            return;
        }

        setLoading(true)

        //form만들기
        const data = new FormData();
        // terminalData의 데이터 추가
        for (const key in formData) {
            if (key !== 'joinFiles'){
                data.append(key, formData[key]);
            }
        }

        Array.from(selectedFiles).forEach((file) => {
            data.append("files", file);
        });

        axios.patch(`/api/shop/${formData.shopCode}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }}).then(() => {
                alert('회원 정보 수정 완료');
                handleClose();
                navigate('/');
        });
        setLoading(false)


    }

    const handleFileChange = (index) => (event) => {
        const file = event.target.files[0];
        if (file) {
            const originalExtension = file.name.split(".").pop(); // 원본 파일의 확장자 추출
            const newFileName = `${event.target.name}.${originalExtension}`; // 새 파일명 생성

            const newFile = new File([file], newFileName, {type: file.type}); // 새 파일 객체 생성

            setSelectedFiles((prevFiles) => {
                let newFiles = [...prevFiles];
                newFiles[index] = newFile; // 새로운 파일명으로 해당 인덱스에 저장
                // 배열의 빈 항목들 제거
                newFiles = newFiles.filter(
                    (file) => file != null && file !== ""
                );
                return newFiles;
            });
        }
    };
    const handleOpen = (event) => {
        setOpen(true);
        setAddressTarget(event.target.name);
    };
    const handleChange = (e) => {
        const field = e.target.name;
        setFormData({...formData, [field]: e.target.value});
    };

    const phoneNumberHandler = (e, idx) => {
        const newPhoneNumber = [...phoneNumber];
        newPhoneNumber[idx] = e.target.value;
        setPhoneNumber(newPhoneNumber);
        setFormData({
            ...formData,
            shopTell: `${newPhoneNumber[0]}-${newPhoneNumber[1]}-${newPhoneNumber[2]}`,
        });
    };
    const isValidKoreanName = (name) => /^[가-힣]{2,4}$/.test(name);
    const isValidPhoneNumber = (number) => /^\d{3,4}$/.test(number);
    // 한글 텍스트 유효성 검사: 최대 10글자 한글
    const isValidKoreanText = (text) => /^[가-힣]{1,10}$/.test(text);
    const isValidKoreanEnglishNumberText = (text) =>
        /^[가-힣a-zA-Z0-9]{1,10}$/.test(text);

    const isValidFormat = (number) => {
        return /^(\d{1,2}(\.\d)?|100(\.0)?)$/.test(number);
    };
    const isValidDecimal = (number) => {
        // 형식 검증
        if (!isValidFormat(number)) {
            return false;
        }
        return parseFloat(number) <= 100.0;
    };

    const phoneNumberHandler2 = (e, idx) => {
        const newPhoneNumber = [...phoneNumber2];
        newPhoneNumber[idx] = e.target.value;
        setPhoneNumber2(newPhoneNumber);
        setFormData({
            ...formData,
            postTell: `${newPhoneNumber[0]}-${newPhoneNumber[1]}-${newPhoneNumber[2]}`,
        });
    };
    const completeHandler = (data) => {
        if (addrTarget === "addrBtn") {
            formData.shopAddr1 = data.zonecode;
            formData.shopAddr2 = data.roadAddress;
        }
        else {
            formData.postAddr1 = data.zonecode;
            formData.postAddr2 = data.roadAddress;
        }
        handleClose();
    };

    const checkValidation = () => {
        if (!isValidKoreanName(formData.shopOwner)) {
            alert("대표자명을 확인하세요");
            return false;
        } else if (
            !isValidPhoneNumber(phoneNumber[1]) &&
            !isValidPhoneNumber(phoneNumber[2])
        ) {
            alert("휴대폰번호를 확인하세요");
            return false;
        }
        return true;
    };

    return (
        <>
            {/*<Typography*/}
            {/*    variant="h5"*/}
            {/*    style={{*/}
            {/*        borderBottom: "2px solid black",*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {!isEdit ? '회원 상세 정보' : '회원 정보 수정'}*/}
            {/*</Typography>*/}
            <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom="2px solid black">
                <Typography variant="h5">
                    {!isEdit ? '회원 상세 정보' : '회원 정보 수정'}
                </Typography>
                {memberInfo.memRole === 'ROLE_ADMIN' &&
                    <span style={{textAlign: 'end', fontSize: '10px'}}>
                {' 등록 : ' + data.memName}
                </span>
                }
                {data.permit === '대기' ?
                    <UpdateIcon sx={{color: '#ff9100'}}></UpdateIcon>
                    : data.permit === '승인' ?
                        <CheckIcon color="success"></CheckIcon>
                        :
                        <CancelIcon sx={{color: '#ff0000'}}></CancelIcon>
                }
            </Box>


            <TableContainer component={Paper}>
                {!isEdit ?
                    
                    // 기본 정보 출력
                    <BasicInfoModal data={data} handleClose={handleClose}></BasicInfoModal>
                    :
                    <>
                        {/*수정 폼*/}
                        <form onSubmit={patchHandler} id="memberForm">
                            <Grid container spacing={1} sx={{marginTop: 1}}>
                                <BusinessInfo
                                    isValidKoreanText={isValidKoreanText}
                                    isValidKoreanName={isValidKoreanName}
                                    isValidKoreanEnglishNumberText={isValidKoreanEnglishNumberText}
                                    handleOpen={handleOpen}
                                    formData={formData}
                                    handleChange={handleChange}
                                    phoneNumber={phoneNumber}
                                    phoneNumberHandler={phoneNumberHandler}
                                    isValidPhoneNumber={isValidPhoneNumber}
                                    isValidDecimal={isValidDecimal}
                                    bankInfo={bankInfo}
                                    phoneNumber2={phoneNumber2}
                                    setFormData={setFormData}
                                    setPhoneNumber2={setPhoneNumber2}
                                    phoneNumberHandler2={phoneNumberHandler2}
                                ></BusinessInfo>
                            </Grid>
                            <Typography variant="h6" align="center" gutterBottom sx={{marginTop: 1}}>
                                첨부 파일
                            </Typography>
                            <Grid container spacing={2} sx={{marginTop: 1}}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {fileNameList.map((fileName, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" align="center"
                                                           sx={{fontSize: '10px', width: '65%'}}>
                                                    {fileName}
                                                </TableCell>
                                                <TableCell align="center" component="th" sx={{fontSize: '12px'}}>
                                                    <input
                                                        accept="image/*"
                                                        style={{display: "none"}}
                                                        id={`edit-file-${index}`}
                                                        type="file"
                                                        name={fileName}
                                                        onChange={handleFileChange(index)}
                                                    />
                                                    <label htmlFor={`edit-file-${index}`}
                                                           style={{textAlign: "center", display: "inline-block"}}>
                                                                <Button
                                                                    disabled
                                                                    variant="outlined"
                                                                    size={'small'}
                                                                    style={{
                                                                        borderColor: 'black',
                                                                        color: "black",
                                                                        fontSize: 10,
                                                                    }}
                                                                >수정</Button>
                                                    </label>

                                                </TableCell>
                                            </TableRow>
                                        ))
                                        }

                                    </TableBody>
                                </Table>
                                {selectedFiles.map((fileName, index) => (
                                    <Grid item xs={12} key={index}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            margin="normal"
                                            disabled
                                            label={`선택된 파일 ${index + 1}`}
                                            value={fileName.name}
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>
                                ))}
                            </Grid>

                        </form>
                    </>

                }

                {/*<MemberEdit formData={formData} setFormData={setFormData}></MemberEdit>*/}
            </TableContainer>

            <Grid container sx={{marginTop: 3}} justifyContent="center">
                {!isEdit ?
                    <>
                        <Grid item xs={4} align="center">
                            <Button
                                type={'button'}
                                onClick={editHandler}
                                variant="outlined"
                                size={'small'}
                                style={{
                                    borderColor: 'black',
                                    color: "black",
                                    fontSize: 14,
                                }}>수정</Button>
                        </Grid>
                        <Grid item xs={4} align="center">
                            <Button
                                onClick={deleteHandler}
                                type={'button'}
                                variant="outlined"
                                size={'small'}
                                style={{
                                    borderColor: 'black',
                                    color: "black",
                                    fontSize: 14,
                                }}
                            >삭제</Button>
                        </Grid>
                    </>
                    :
                    <>

                        <Grid item xs={4} align="center">
                            <Button
                                type="submit"
                                form="memberForm"
                                variant="outlined"
                                size={'small'}
                                style={{
                                    borderColor: 'black',
                                    color: "black",
                                    fontSize: 14,
                                }}>완료</Button>
                        </Grid>
                        <Grid item xs={4} align="center">
                            <Button
                                type={'button'}
                                onClick={editHandler}
                                variant="outlined"
                                size={'small'}
                                style={{
                                    borderColor: 'black',
                                    color: "black",
                                    fontSize: 14,
                                }}
                            >취소</Button>
                        </Grid>
                    </>

                }
            </Grid>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        주소 검색
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        <DaumPostcode onComplete={completeHandler}/>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}
