import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist({ key: 'atom', storage: sessionStorage });

export const memberState = atom({
    key: 'memberState', // unique ID (with respect to other atoms/selectors)
    default: {
        memCode: '',
        memRole: '',
    }, // default value (aka initial value)
    effects_UNSTABLE: [persistAtom],
});