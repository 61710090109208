import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Join from "./page/Join";
import './App.css';
import './index.css';
import {JoinId} from "./page/JoinId";
import Login from "./page/Login";
import MemberList from "./page/MemberList";
import {Container} from "@mui/material";
import {Header} from "./page/Header";
import {useRecoilState, useRecoilValue} from "recoil";
import {memberState} from "./store/MemberStore";
import {ChangePw} from "./page/ChangePw";
import axios from "axios";


function ProtectedRoute({children, allowedRoles}) {
    const memberInfo = useRecoilValue(memberState);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!allowedRoles.includes(memberInfo.memRole)) {
            // 허용된 역할이 아닌 경우 /login으로 리다이렉트
            navigate('/login', {replace: true, state: {from: location}});
        }
    }, [memberInfo, allowedRoles, navigate, location]);

    return children;
}

const theme = createTheme({
    typography: {
        fontFamily: 'Pretendard-Regular',
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '8px', // 모든 TableCell에 적용되는 패딩 값
                },
            },
        },
    },
});

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoginRoute, setIsLoginRoute] = useState(false);
    const [memberInfo, setMemberInfo] = useRecoilState(memberState);


    useEffect(() => {
        // 엑세스 토큰이 쿠키에 존재하는지 확인
        const accessToken = getCookie('accessToken');

        // 엑세스 토큰이 없을 경우에만 새로고침 로직 실행
        if (!accessToken) {
            const refreshToken = getCookie('refreshToken');
            if (refreshToken) {
                axios.post('/api/auth/refreshToken', { refreshToken })
                    .then(response => {
                        // 성공 시 새 엑세스 토큰 저장 및 사용자 정보 설정
                        document.cookie = `accessToken=${response.data.accessToken}; path=/;`;
                        setMemberInfo({ memCode: response.data.memCode, memRole: response.data.memRole });

                        // 권한에 따라 적절한 페이지로 라우팅
                        if (['ROLE_ADMIN', 'ROLE_USER'].includes(response.data.memRole)) {
                            navigate('/'); // 메인 페이지나 대시보드 등으로 변경 가능
                        } else {
                            navigate('/login');
                        }
                    })
                    .catch(error => {
                        console.error('Token refresh failed', error);
                        navigate('/login'); // 실패 시 로그인 페이지로 리다이렉트
                    });
            }
        }
    }, [navigate, setMemberInfo]);

    useEffect(() => {
        // 현재 경로가 /login인지 확인하고 상태를 업데이트합니다.
        setIsLoginRoute(location.pathname === '/login');
    }, [location]); // location이 변경될 때마다 useEffect가 실행됩니다.


    const getCookie = (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    };

    return (
        <ThemeProvider theme={theme}>
            {!isLoginRoute &&
                <Header></Header>
            }
            <Container maxWidth="sm" sx={{
                maxWidth: 900,
                marginLeft: 'auto',
                marginRight: 'auto',
                position: 'relative',
                paddingTop: '20px'
            }}>
                <Routes>
                    <Route path="/join" element={
                        <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}>
                            <Join/>
                        </ProtectedRoute>
                    }/>
                    <Route path="/changePw" element={
                        <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}>
                            <ChangePw/>
                        </ProtectedRoute>
                    }/>
                    <Route path="/login" element={<Login/>}></Route>
                    <Route path="/regManage" element={
                        <ProtectedRoute allowedRoles={['ROLE_ADMIN']}>
                            <JoinId/>
                        </ProtectedRoute>
                    }/>
                    <Route path="/" element={
                        <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_USER']}>
                            <MemberList/>
                        </ProtectedRoute>
                    }/>
                </Routes>
            </Container>
        </ThemeProvider>
    )
}

export default App;
