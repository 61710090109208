import React, {useEffect, useState} from 'react'
import {AppBar, Box, Button, Toolbar, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {memberState} from "../store/MemberStore";
import axios from "../axiosInstance";

export const Header = () => {

    const navigate = useNavigate();
    const memberInfo = useRecoilValue(memberState);
    const [isAdmin, setIsAdmin] = useState(false);


    useEffect(() => {
        setIsAdmin(memberInfo.memRole === 'ROLE_ADMIN');
    }, [memberInfo]);

    const logoutHandler = () => {
        const refreshToken = localStorage.getItem('refreshToken');
        axios.delete('/api/auth/logout', { data: { refreshToken } }).then(() => {
            document.cookie = 'refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
            document.cookie = 'accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
            navigate('/login');
        })
    }

    const regManage = () => {
        navigate('/regManage');
    }

    const changePw = () => {
        navigate('/changePw');
    }

    const listPage = () => {
        navigate('/');
    }
    return (
        <>
            <AppBar position="static" sx={{bgcolor: 'black'}}>
                <Toolbar sx={{bgcolor: 'black', height: '50px'}}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        sx={{
                            mr: 2,
                            display: {xs: 'flex'},
                            flexGrow: 1,
                            letterSpacing: '.2rem',
                            color: 'inherit',
                            fontSize: '17px'
                        }}
                    >
                        {isAdmin ? '관리자페이지' : 'UPUP'}
                    </Typography>
                    {isAdmin &&
                        <Button size={'small'} onClick={regManage}
                                sx={{my: 2, color: 'white', display: 'block'}}>영업자 등록</Button>
                    }
                    <Button size={'small'} onClick={listPage}
                            sx={{my: 2, color: 'white', display: 'block'}}>가맹점 목록</Button>
                    {!isAdmin &&
                        <Button size={'small'} onClick={changePw}
                                sx={{my: 2, color: 'white', display: 'block'}}>비밀번호 변경</Button>
                    }
                    <Button size={'small'} onClick={logoutHandler}
                            sx={{my: 2, color: 'white', display: 'block'}}>로그아웃</Button>
                </Toolbar>
            </AppBar>
        </>
    )
}
