import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Button, Typography, Checkbox, Box, FormLabel, RadioGroup, FormControlLabel, Radio,
} from "@mui/material";
import React from "react";
import {PostInfo} from "./PostInfo";


export default function BusinessInfo({
                                         formData,
                                         handleChange,
                                         phoneNumber,
                                         phoneNumberHandler,
                                         isValidPhoneNumber,
                                         handleOpen,
                                         isValidKoreanName,
                                         isValidKoreanEnglishNumberText,
                                         isValidDecimal,
                                         bankInfo,
                                         phoneNumber2,
                                         phoneNumberHandler2,
                                         setFormData,
                                         setPhoneNumber2
                                     }) {

    const checkboxHandler = (event) => {
        if (event.target.checked) {
            setFormData({
                ...formData,
                postName: formData.shopOwner,
                postTell: formData.shopTell,
                postAddr1: formData.shopAddr1,
                postAddr2: formData.shopAddr2,
                postAddrDetail: formData.shopAddrDetail,
            });
            setPhoneNumber2([...phoneNumber])

        } else {
            setFormData({
                ...formData,
                postName: '',
                postTell: '',
                postAddr1: '',
                postAddr2: '',
                postAddrDetail: '',
            });
            setPhoneNumber2(["010", "", ""])

        }
    }

    const radioHandleChange = (event) => {
        setFormData({
            ...formData,
            method: event.target.value
        });
    };

    return (
        <>
            <Grid item xs={5}>
                <TextField
                    fullWidth
                    label="대표자명(담당자)"
                    name="shopOwner"
                    value={formData.shopOwner}
                    onChange={handleChange}
                    variant="standard"
                    required
                    error={
                        !isValidKoreanName(formData.shopOwner) &&
                        formData.shopOwner !== ""
                    }
                    helperText={
                        !isValidKoreanName(formData.shopOwner) &&
                        formData.shopOwner !== ""
                            ? "2~4자리 한글"
                            : ""
                    }
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                />
            </Grid>

            <Grid item xs={7}>
                <TextField
                    fullWidth
                    label="상호명"
                    name="shopTitle"
                    value={formData.shopTitle}
                    onChange={handleChange}
                    variant="standard"
                    required
                    error={
                        !isValidKoreanEnglishNumberText(formData.shopTitle) &&
                        formData.shopTitle !== ""
                    }
                    helperText={
                        !isValidKoreanEnglishNumberText(formData.shopTitle) &&
                        formData.shopTitle !== ""
                            ? ""
                            : ""
                    }
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth variant="standard">
                    <InputLabel>휴대전화</InputLabel>
                    <Select
                        name="memTellFirst"
                        value={phoneNumber[0]}
                        onChange={(event) => {
                            phoneNumberHandler(event, 0);
                        }}
                    >
                        <MenuItem value="010">010</MenuItem>
                        <MenuItem value="011">011</MenuItem>
                        <MenuItem value="016">016</MenuItem>
                        <MenuItem value="016">017</MenuItem>
                        <MenuItem value="019">019</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="중간자리"
                    name="memTellSecond"
                    value={phoneNumber[1]}
                    onChange={(event) => {
                        phoneNumberHandler(event, 1);
                    }}
                    variant="standard"
                    required
                    error={
                        !isValidPhoneNumber(phoneNumber[1]) &&
                        phoneNumber[1] !== ""
                    }
                    helperText={
                        !isValidPhoneNumber(phoneNumber[1]) &&
                        phoneNumber[1] !== ""
                            ? "3~4자리 숫자"
                            : ""
                    }
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="뒷자리"
                    name="memTellThird"
                    value={phoneNumber[2]}
                    onChange={(event) => {
                        phoneNumberHandler(event, 2);
                    }}
                    variant="standard"
                    required
                    error={
                        !isValidPhoneNumber(phoneNumber[2]) &&
                        phoneNumber[2] !== ""
                    }
                    helperText={
                        !isValidPhoneNumber(phoneNumber[2]) &&
                        phoneNumber[2] !== ""
                            ? "3~4자리 숫자"
                            : ""
                    }
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="업태"
                    name="shopType"
                    value={formData.shopType}
                    onChange={handleChange}
                    variant="standard"
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="종목"
                    name="shopItem"
                    value={formData.shopItem}
                    onChange={handleChange}
                    variant="standard"
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="우편번호"
                    name="shopAddr1"
                    value={formData.shopAddr1}
                    onChange={handleChange}
                    variant="standard"
                    required
                    disabled
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                    InputProps={{
                        sx: {
                            '::placeholder': { // placeholder 스타일 적용
                                fontSize: '12px', // 원하는 글자 크기로 설정
                            },
                            input: { // input 필드 스타일
                                fontSize: '12px', // 입력 텍스트 글자 크기도 조절할 수 있습니다
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item xs={5}>
                <TextField
                    fullWidth
                    label="주소"
                    name="shopAddr2"
                    value={formData.shopAddr2}
                    onChange={handleChange}
                    variant="standard"
                    required
                    disabled
                    InputLabelProps={{
                        style: {fontSize: '12px'}
                    }}
                    InputProps={{
                        sx: {
                            '::placeholder': { // placeholder 스타일 적용
                                fontSize: '12px', // 원하는 글자 크기로 설정
                            },
                            input: { // input 필드 스타일
                                fontSize: '12px', // 입력 텍스트 글자 크기도 조절할 수 있습니다
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item xs={4} sx={{paddingRight: 1,}}>
                <Button
                    fullWidth
                    name="addrBtn"
                    variant="outlined"
                    size={'small'}
                    style={{
                        borderColor: 'black',
                        color: "black",
                        fontSize: 9,
                        marginTop: '20px',
                        verticalAlign: 'bottom',
                    }}
                    onClick={handleOpen}
                >
                    검색
                </Button>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="상세주소"
                    name="shopAddrDetail"
                    value={formData.shopAddrDetail}
                    onChange={handleChange}
                    variant="standard"
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label="수수료"
                    name="shopCharge"
                    value={formData.shopCharge}
                    onChange={handleChange}
                    variant="standard"
                    required
                    error={
                        !isValidDecimal(formData.shopCharge) &&
                        formData.shopCharge !== ""
                    }
                    helperText={
                        !isValidDecimal(formData.shopCharge) &&
                        formData.shopCharge !== ""
                            ? ""
                            : ""
                    }
                />
            </Grid>
            <Grid item xs={6} style={{display: 'flex', alignItems: 'flex-end'}}>
                {/* span 요소를 flex 컨테이너로 만들어서 텍스트를 중앙 정렬합니다. */}
                <span style={{paddingTop: '7px', fontSize: '14px'}}>% (부가세 포함)</span>
            </Grid>
            <Grid item xs={6}>
                {/*<TextField*/}
                {/*    fullWidth*/}
                {/*    label={'은행명'}*/}
                {/*    name="shopBankName"*/}
                {/*    value={formData.shopBankName}*/}
                {/*    onChange={handleChange}*/}
                {/*    variant="standard"*/}
                {/*/>*/}
                <InputLabel sx={{fontSize: '12px'}}>은행명</InputLabel>
                <Select
                    size="small"
                    name="shopBankName"
                    onChange={handleChange}
                    value={formData.shopBankName}
                    fullWidth
                    variant="standard"
                >
                    {bankInfo.map(bank => (
                        <MenuItem value={bank.BANK_NAME}>{bank.BANK_NAME}</MenuItem>
                    ))}
                </Select>

            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="예금주"
                    name="shopBankOwner"
                    value={formData.shopBankOwner}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="계좌번호"
                    name="shopBankNum"
                    value={formData.shopBankNum}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>

            <Grid item xs={12} style={{paddingTop: 4}}>
                        <span style={{
                            fontSize: '10px',
                            color: 'grey',
                            display: 'block',
                            textAlign: 'right',
                            paddingRight: '8px'
                        }}>* 자동입금을 원하시면 계좌정보를 입력해 주세요</span>
            </Grid>

            <Grid item xs={4} style={{paddingTop: 4}}>
                {/*    총판  */}
                <TextField
                    fullWidth
                    label="총판(전 지사)"
                    name="lv4"
                    value={formData.lv4}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={4} style={{paddingTop: 4}}>
                {/*    대리점  */}
                <TextField
                    fullWidth
                    label="대리점(전 총판)"
                    name="lv5"
                    value={formData.lv5}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={4} style={{paddingTop: 4}}>
                {/*    영업자  */}
                <TextField
                    fullWidth
                    label="영업자(전 대리점)"
                    name="lv6"
                    value={formData.lv6}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} style={{paddingTop: 4, marginTop: '20px'}}>
                {/*    라디오     */}
                <FormControl>
                    <FormLabel id="radio-buttons-group">정산방식</FormLabel>
                    <RadioGroup
                        aria-labelledby="radio-buttons-group"
                        name="method"
                        value={formData.method} // RadioGroup의 value를 formData.method로 설정
                        onChange={radioHandleChange}
                    >
                        <FormControlLabel value='1' control={<Radio/>}  sx={{ '& .MuiTypography-root': { fontSize: 14 } }} label="가맹점 계좌 자동 송금 정산"/>
                        <FormControlLabel value='2' control={<Radio/>}  sx={{ '& .MuiTypography-root': { fontSize: 14 } }} label="지갑(가상계좌) 정산 : 지갑을 통해 원하는 계좌로 직접 송금"/>
                    </RadioGroup>
                </FormControl>
            </Grid>


            <Grid item xs={12}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-end', // 모든 요소를 하단에 정렬
                        borderBottom: '2px solid black', // Box 전체에 밑줄 적용
                        paddingTop: '40px', // 상단 여백 조정
                        paddingBottom: '8px', // 밑줄과 요소 사이의 간격 조정
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            flexGrow: 1
                        }}
                    >
                        배송 정보
                    </Typography>

                    <Checkbox sx={{padding: 0, marginRight: '4px'}}
                              onChange={checkboxHandler}/> {/* 체크박스와 텍스트 사이의 간격 최소화 */}

                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: '12px', // 폰트 크기 조정
                            marginBottom: '3px'
                        }}
                    >
                        회원 정보와 동일
                    </Typography>
                </Box>
            </Grid>

            <PostInfo formData={formData} handleChange={handleChange} isValidKoreanName={isValidKoreanName}
                      phoneNumber2={phoneNumber2}
                      phoneNumberHandler2={phoneNumberHandler2}
                      isValidPhoneNumber={isValidPhoneNumber}
                      handleOpen={handleOpen}
            ></PostInfo>

            <Grid item xs={12} sx={{marginTop: 5}}>
                <TextField
                    fullWidth
                    label="메모"
                    name="memo"
                    value={formData.memo}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>
        </>
    );
}
