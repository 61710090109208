import React, {useEffect, useState} from 'react'
import axios from '../axiosInstance';
import {useNavigate} from "react-router-dom";
import {
    Pagination, PaginationItem,
    Box,
    Fab,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography, Backdrop, CircularProgress
} from "@mui/material";
import ListCard from "../page/components/ListCard";
import MemberModal from "../page/components/MemberModal";
import AddIcon from "@mui/icons-material/Add";
import {useRecoilValue} from "recoil";
import {memberState} from "../store/MemberStore";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
export default function MemberList() {
    const navigate = useNavigate();
    const [memberList, setMemberList] = useState([]);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [keyword, setKeyword] = useState({category: '', word: ''});
    const [fabPosition, setFabPosition] = useState(16);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState({});
    const memberInfo = useRecoilValue(memberState);
    const [loading, setLoading] = useState(false);
    const getURL = memberInfo.memRole === 'ROLE_ADMIN' ? '/api/admin/shop?page=' + page : '/api/shop/' + memberInfo.memCode + '?page=' + page;

    useEffect(() => {
        const updateFabPosition = () => {
            // 화면 너비에 따라 Fab 위치 계산
            const windowWidth = window.innerWidth;
            const containerMaxWidth = 600;
            if (windowWidth > containerMaxWidth) {
                // (화면 너비 - 컨테이너 최대 너비) / 2 - 추가 여백
                setFabPosition((windowWidth - containerMaxWidth) / 2 - 16);
            } else {
                setFabPosition(16);
            }
        };

        // 리사이즈 이벤트 리스너 등록 및 초기 위치 설정
        window.addEventListener('resize', updateFabPosition);
        updateFabPosition();
        getMemberList();
        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', updateFabPosition);
        };
    }, []);

    useEffect(() => {
        getMemberList();
    }, [page]);

    const onPageChange = (e, newPage) => {
        setPage(newPage);
    };

    const getMemberList = (event) => {
        let finalUrl = getURL;
        if (event && event.target.id === 'searchBtn'){
            finalUrl = getURL + '&' + keyword.category + '=' + keyword.word;
        }
        axios.get(finalUrl).then((response) => {
            setMemberList(response.data.content);
            setPageData(response.data)
            if (event && event.target.id === 'refreshBtn'){
                setKeyword({category: '', word: ''})
            }

        }).catch(() => {
        });


    };
    const handleOpen = (data) => {
        setData(data)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        getMemberList();
    };

    const searchKeywordHandler = (event) => {
        setKeyword({...keyword, [event.target.name]: event.target.value});
    }

    const regMember = () => {
        navigate('/join');
    }

    return (
        <>
            <Typography variant="h5" align="center" gutterBottom>
                가맹점 목록
            </Typography>
            <Grid container spacing={2} justifyContent="flex-end" sx={{marginBottom: 2}}>
                <Grid item xs={1} container alignItems="end" justifyContent="center">

                    <RefreshIcon id={'refreshBtn'} onClick={getMemberList}  sx={{ marginBottom: 0.5 }} />
                </Grid>
                <Grid item xs={4} container alignItems="end" justifyContent="center" sx={{ paddingLeft: 1 }}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel>검색항목</InputLabel>
                        <Select value={keyword.category} onChange={searchKeywordHandler} name="category">
                            <MenuItem value="shopTitle">상호명</MenuItem>
                            <MenuItem value="shopTell">연락처</MenuItem>
                            <MenuItem value="shopOwner">대표자명</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} container alignItems="center" justifyContent="center">
                    <TextField value={keyword.word} onChange={searchKeywordHandler} fullWidth label={'검색어'} name={'word'}
                               variant="standard"/>
                </Grid>
                <Grid item xs={1} container alignItems="end" justifyContent="center">
                    <SearchIcon onClick={getMemberList} id={'searchBtn'} sx={{ marginBottom: 0.5 }} />
                </Grid>
            </Grid>


            <Grid item xs={12}>
                <ListCard
                    handleOpen={handleOpen}
                    memberList={memberList}
                ></ListCard>
            </Grid>
            <Grid item xs={12}>
                <Pagination
                    count={pageData.pageSize > 0 ? Math.ceil(pageData.totalElements / pageData.pageSize) : 0}
                    page={pageData.currentPage || 1}
                    onChange={onPageChange}
                    size="medium"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "15px 0",
                    }}
                    renderItem={(item) => (
                        <PaginationItem {...item} sx={{ fontSize: 12 }} />
                    )}
                />
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        position: 'relative', // 상대적 위치 지정
                        maxWidth: '600px',
                        minWidth: '300px',
                        width: '45%',
                        maxHeight: '80%',
                        overflowY: 'scroll',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 3,
                    }}
                >
                    <CloseIcon
                        sx={{
                            position: 'absolute', // 절대적 위치 지정
                            top: 0, // 상단에 위치
                            right: 0, // 우측에 위치
                            cursor: 'pointer' // 마우스 오버 시 포인터 변경
                        }}
                        onClick={handleClose} // 클릭 시 닫기 이벤트
                    />
                    <MemberModal setLoading={setLoading} handleClose={handleClose} data={data}/>
                </Box>
            </Modal>
            <Fab onClick={regMember} size='small' aria-label="add"
                 sx={{position: 'fixed', bottom: 16, right: fabPosition}}>
                <AddIcon/>
            </Fab>


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
