import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React from "react";

export const RegIdForm = ({
                              handleChange,
                              formData,
                              isValidKoreanEnglishNumberText,
                              handleSubmit,
                              isValidId,
                              phoneNumber,
                              phoneNumberHandler, isValidPhoneNumber,
                              bankInfo, checkDuplicateId

                          }) => {
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid item xs={12} container spacing={2} justifyContent={'center'} alignItems="center">
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            label={'영업자명'}
                            name="memName"
                            value={formData.memName}
                            onChange={handleChange}
                            variant="standard"
                            required
                            error={
                                !isValidKoreanEnglishNumberText(formData.memName) &&
                                formData.memName !== ""
                            }
                            helperText={
                                !isValidKoreanEnglishNumberText(formData.memName) &&
                                formData.memName !== ""
                                    ? "10글자 미만 입력 가능, 공백과 특수문자는 입력할 수 없습니다."
                                    : ""
                            }
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            label="아이디"
                            name="memId"
                            value={formData.memId}
                            onChange={handleChange}
                            variant="standard"
                            required
                            error={
                                !isValidId(formData.memId) && formData.memId !== ""
                            }
                            helperText={
                                !isValidId(formData.memId) && formData.memId !== ""
                                    ? "아이디는 최소 4자 이상이어야 합니다."
                                    : ""
                            }
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth variant="standard">
                            <InputLabel>휴대전화</InputLabel>
                            <Select
                                name="memTellFirst"
                                value={phoneNumber[0]}
                                onChange={(event) => {
                                    phoneNumberHandler(event, 0);
                                }}
                            >
                                <MenuItem value="010">010</MenuItem>
                                <MenuItem value="011">011</MenuItem>
                                <MenuItem value="016">016</MenuItem>
                                <MenuItem value="016">017</MenuItem>
                                <MenuItem value="019">019</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="중간자리"
                            name="memTellSecond"
                            value={phoneNumber[1]}
                            onChange={(event) => {
                                phoneNumberHandler(event, 1);
                            }}
                            variant="standard"
                            required
                            error={
                                !isValidPhoneNumber(phoneNumber[1]) &&
                                phoneNumber[1] !== ""
                            }
                            helperText={
                                !isValidPhoneNumber(phoneNumber[1]) &&
                                phoneNumber[1] !== ""
                                    ? "3~4자리 숫자"
                                    : ""
                            }
                            InputLabelProps={{
                                style: {fontSize: '12px'}
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="뒷자리"
                            name="memTellThird"
                            value={phoneNumber[2]}
                            onChange={(event) => {
                                phoneNumberHandler(event, 2);
                            }}
                            variant="standard"
                            required
                            error={
                                !isValidPhoneNumber(phoneNumber[2]) &&
                                phoneNumber[2] !== ""
                            }
                            helperText={
                                !isValidPhoneNumber(phoneNumber[2]) &&
                                phoneNumber[2] !== ""
                                    ? "3~4자리 숫자"
                                    : ""
                            }
                            InputLabelProps={{
                                style: {fontSize: '12px'}
                            }}
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <InputLabel sx={{fontSize: '12px'}}>은행명</InputLabel>
                        <Select
                            size="small"
                            name="bankName"
                            onChange={handleChange}
                            value={formData.bankName}
                            fullWidth
                            variant="standard"
                        >
                            {bankInfo.map((bank, index) => (
                                <MenuItem key={bank.BANK_NAME + index}
                                          value={bank.BANK_NAME}>{bank.BANK_NAME}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            label="예금주"
                            name="bankOwner"
                            value={formData.bankOwner}
                            onChange={handleChange}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextField
                            fullWidth
                            label="계좌번호"
                            name="bankNum"
                            value={formData.bankNum}
                            onChange={handleChange}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item xs={10}>
                        <TextField
                            fullWidth
                            label="메모"
                            name="memMemo"
                            type="text"
                            value={formData.memMemo}
                            onChange={handleChange}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={10} style={{paddingTop: 4}}>
                        <span style={{
                            fontSize: '10px',
                            color: 'grey',
                            display: 'block',
                            textAlign: 'right',
                            paddingRight: '8px'
                        }}>+ 초기 비밀번호는 1234입니다.</span>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={checkDuplicateId}
                            style={{
                                backgroundColor: "black",
                                fontSize: 14,
                            }}
                        >
                            중복검사
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            style={{
                                backgroundColor: "black",
                                fontSize: 14,
                            }}
                        >
                            생성
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
